.form-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid black;
    border-radius: 10px;
    margin-inline: 15px;
    margin-bottom: 10px;
    height: 60px;
}

.form-container> .box1{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 70%;
}

.form-container> .box2{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    
}