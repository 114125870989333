
.alert-box{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0px auto;
}

.alert-box> .icon{
    margin-bottom: 30px;
}

.alert-box> .btn{
    width: 100%;
    margin-top: 30px;
}