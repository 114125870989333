

.image-option-box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 5px;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.664);

    border: solid 1px rgb(38, 38, 221);
    border-radius: 5px;
    width: 150px;
    margin-right: 10px;
    height: 160px;
}

.image-option-box> .delete, .image-option-box> .save{
    position: fixed;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-left: 105px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: rgba(211, 219, 219, 0.795);
}

.image-option-box> .delete:hover{
    background-color: rgb(243, 167, 167);
    color:rgb(252, 0, 0);
}

.image-option-box> .save:hover{
    background-color: rgba(123, 167, 170, 0.712);
    color:rgb(54, 54, 255);
}

.image-option-box> .image{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-option-box> .image> img{
    margin-top: 20px;
    width: 100%;
    max-height: 95px;
}
.image-option-box> .image> .img-upload-btn{
    margin-top:10px;
    color: rgb(54, 54, 255);
}

.image-option-box> .image> .img-upload-btn:hover{
    color:rgb(70, 70, 221);
}

.legenda{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: azure;
}

.legenda> .index{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin-right: 10px;
    color: rgb(54, 54, 255);
    background-color: rgba(211, 219, 219, 0.795);
}

.legenda> .index, .legenda> .text{
    font-size: x-small;
    font-weight: bolder;

}

.legenda> .index, .legenda> input{
    padding: 4px;
    max-width: 100%;
    border-radius: 5px;
    border: solid 1px rgb(54, 54, 255);
}

/*Image content box*/
.image-content-box> .options{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;
}