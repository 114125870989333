
.question-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-inline: 10px;
    margin-bottom: 2px;
    background-color: white;
}

.question-box> .coluna1{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

}

.question-box> .coluna1> .order{
    height: 100%;
    margin-inline: 5px;
    margin-right: 10px;
    color:rgb(0, 102, 255);
}

.question-box> .coluna1> .question-item> .type{
    font-size: small;
    font-weight: bolder;
    width: max-content;
    padding-inline: 5px;
    border-radius: 3px;
    border: 1px solid rgb(0, 102, 255);
}

.delete-question:hover{
    color: rgba(241, 43, 43, 0.719);
}