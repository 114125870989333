

#underline-input, #blanck-input, .titulo, .descricao{
    border-style: none;
    width: 100%;
    height: 30px;
    color: rgb(55, 59, 63);
    background: none;
}

/*Remove border when you click*/
#underline-input:focus, #blanck-input:focus, .titulo:focus, .descricao:focus{
    outline: none;
}

.titulo{
    font-size: larger;
}

.medium{
    font-size: medium;
}

.descricao{
    font-size: medium;
}