.setting-box{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.setting-box> .s-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.setting-box> .s-header>.nav-link> *{
    margin-inline: 5px;
}

.setting-box> .s-header> .action{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.setting-box> .s-header> .action> div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-inline: 10px;
    border-radius: 5px;
    border: 1px solid black;
}
