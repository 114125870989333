
.metrica-box{
    margin-top: 100px;
    width: 100%;
    height: 100%;
}

.metrica-box> .content{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.metrica-box> .content> div{
    width: 50%;
    height: 100%;
}

.metrica-box> .content> .bg-image{
    margin-top: 30px;
}

.metrica-box> .content> .box> .header{
    margin-bottom: 25px;
}


/*Area de estilizacao do componente plataform*/
.platafor-box{margin-bottom: 10px;}
.platafor-box> input{
    height: 35px;
    width: 300px;
    padding-inline: 5px;
}