.satisfation-box{
    margin-top: 50px;
}

#satisfation-option{
    width: 50px;
    height: 50px;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: medium;
    font-weight: bold;

    border: none;
    border-radius: 8px;
}

