.list-selector-box{
    width: 100%;
    margin-top: 25px;
}


.list-selector-box> .add-new{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
}

.list-selector-box> .add-new> textarea{
    width: 100%;
    min-height: 100px;
    max-height: 100px;
    border-radius: 5px;
    margin-right: 20px;
    padding-inline: 10px;
}

