
.alert-box{
    display:flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
}



.alert-box> .btn{
    width: 100%;
    margin-top: 20px;
}