.aparence-box{
    margin-top: 80px;
    width: 100%;
    height: 100%;
}

.aparence-box> .content{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: auto;
}
.aparence-box> .content> div{
    align-items: center;
    width: 50%;
    height: 100%;
}

.aparence-box> .content> .image-box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.aparence-box> .content> div> .bg-image{
    margin-top: 30px;
}

.aparence-box> .content> div> .bg-image> div> .delete{
    position: fixed;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-left: 90px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: rgba(211, 219, 219, 0.795);
}

.aparence-box> .content> div> .bg-image> div> .delete:hover{
    background-color: rgb(243, 167, 167);
    color:rgb(252, 0, 0);
}

.aparence-box> .content> div> .bg-image> div> .image> img{
    max-width: 100px;
}