

.multiple-choice-box{
    width: 100%;
    margin-top: 25px;
}

.multiple-choice-box> .add-new{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.multiple-choice-box> .add-new> input{
    width: 100%;
    border-radius: 5px;
    margin-right: 20px;
    padding-inline: 10px;
}


/*Options component*/
.option-box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 5px;
    padding-inline: 5px;

    border: solid 1px rgb(38, 38, 221);
    border-radius: 5px;
}

.option-box> .index{
    margin-inline: 5px;
    font-size: medium;
    display: flex;
    align-items: center;
    justify-content: center;
  
}

.option-box> .delete-button:hover>*{
    color:rgba(241, 57, 57, 0.815);
}

.option-box> .update-button:hover>*{
    color:rgba(61, 224, 211, 0.815);
}