
.preview-header{
    height: 40px;
    width: 100%;
    padding-inline: 30px;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    color: red;
    background-color: rgb(76, 209, 169);
}


.MuiBox-root.css-1ws4ddu{
    padding:0px;
}

.quiz-page{
    height: 100vh;
    padding:0px 20px;
}

.quiz-page> .box{
    height: 100%;
    margin: auto;
    max-width: 700px;
    width: 100%;
    padding: 0px;

    display: flex;
    flex-direction: column;
    justify-content: center;

}

.quiz-page> .box> .pergunta{
    color: whitesmoke;
}

