
.editor-container{
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
}

.editor-nav-container{
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-inline: 20px;
    height: 90%;
    min-height:55px;
    max-height: 60px;
    margin-bottom: 5px;
}

.editor-nav-container> .back{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.questions{
    height: min-content;
    max-height: 80%;
    overflow-y: auto;
}


/*Editor content*/
.content-nav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline: 20px;
    height: 10%;
    border-radius: 12px 12px 0px 0px;
}

.content-nav>div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline: 20px;
    height: 10%;
}


.content-box{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 50%;
    min-height: 30%;
    height: max-content;
    max-height: 100%;
}
