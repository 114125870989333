.cor-line{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.cor-line> .selector{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cor-line> .selector> .color-prev{
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    margin-right: 5px;
    border: none;
    border-radius: 50%;
    overflow: none;
}



.cor-line> .selector> .color-input{
    height: 35px;
    width: 180px;
    color: black;
    border: .25px solid;
    border-radius: 8px;
    padding-inline: 10px;
    font-size: medium;
    font-weight: lighter;
}