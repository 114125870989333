
.logic-box{
    display: flex;
    flex-direction: column;
}

.logic-box> .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.logic-box> .header> div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid rgb(59, 58, 58);
}

.logic-box> .content> .nav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.logic-box> .content> .nav> div{
    width: 35%;
    font-size: small;
    font-weight: 600;
}

.logic-box> .content> .main> div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.logic-box> .content> .main> div> #condition, .logic-box> .content> .main> div> #next-q{
    width: 35%;
}

.logic-box> .content> .main> div> #value{
    width: 28%;
}


.logic-box> .content> .main> div> #next-q{
    display: flex;
    align-items: center;
}

.logic-box> .content> .main> div> #next-q> .to{
    width: 80%;
    margin-right: 10px;
}

.logic-box> .content> .main> div> #next-q> .delete:hover{
    color: rgba(241, 43, 43, 0.719);
}