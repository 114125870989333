
.thank-you{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.thank-you> .icon{
    margin-bottom: 25px;
}

.thank-you> div> *{
    text-align: center;
}

.c{
    text-align: center;
}