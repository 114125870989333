* {
    box-sizing: border-box;
  }
  
  :root,
  html,
  body,
  #root {
    margin: 0;
    padding: 0;
    position: relative;
    height: 100%;
    width: 100%;
  }
  
  .modal-backdrop {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
  
  .sidebar {
    background: #edf1f3;
    height: 100%;
    width: 50%;
    position: absolute;
    right: 0;
  }
