

.input-question> div> input{
    border-style: none;
    width: 100%;
    height: 30px;
    font-size: larger;
    color: aliceblue;
    background: none;
}

.input-question> div> hr{
    margin: 0px;
    padding: 0px;
}

/*Remove border when you click*/
.input-question> div> input:focus{
    outline: none;
}